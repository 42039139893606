import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  IconButton,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import bearPhoto from "./teddy.png";
import ireland from "./ireland-silhouette.png";
import { FaTimes } from "react-icons/fa";
import batmanScore from "./batmanScore.png";
import hydeParkImage from "./RoyalParkRoad.png";
import originalOak from "./original oak.png";
import failedMOT from "./failedMOT.png";
import board from "./board.png";
/*
  Clues
  1. Loc: in house - do we have more doors of wheels? Want a presentation
  2. Loc: hidden in house, go hunting for teddy bear, add photo - Teddy bear's riddle for a word, Worldle Irish
  3. Loc: Hidden by oisins door - batman theme score music
  4. Loc: Centre Room with balacalava, get photo and play tic tac toe - Picture of place in hyde park
  5. Loc: Hyde Park somewhere - Greasy Pig ROT 13
  6. Loc: Greasy Pig - A dingbat of the pub
  7. Loc: Original Oak - things.teeth.oils 74 68 69 6e 67 73 2e 74 65 65 74 68 2e 6f 69 6c 73
  8. Loc: Jack and Thisaths garden, golden egg - Imagine Dragons Audile
  9. Loc: behind imagine dragons vinyl - win the beer pong outside
  10. Loc: beer pong in kitchen, full of concoctions - MOT bill
  11. Loc: my car - Plot of the board I hate
  12. Loc: living room - meet outside for prizes
*/

// TODO: Write written prompts
// Get photos of Hyde Park and Teddy Bear

const clues = [
  {
    number: 1,
    pin: "START",
    clue: () => (
      <Center p={4} flexDirection='column' textAlign='center'>
        <Heading fontSize='xl'>
          Do we have more doors or windows in this house (inc. garden)?
        </Heading>
        <Text>Discuss and present</Text>
      </Center>
    ),
  },
  {
    number: 2,
    pin: "7YY3Y",
    clue: () => (
      <Stack>
        <Image src={bearPhoto} />
      </Stack>
    ),
  },
  {
    number: 3,
    pin: "CARDS",
    clue: () => (
      <Center bg='white'>
        <Image src={ireland} />
      </Center>
    ),
  },
  {
    number: 4,
    pin: "4RAUE",
    clue: () => (
      <Center>
        <Image src={batmanScore} />
      </Center>
    ),
  },
  {
    number: 5,
    pin: "5WICN",
    clue: () => (
      <Center>
        <Image src={hydeParkImage} />
      </Center>
    ),
  },
  {
    number: 6,
    pin: "985UD",
    clue: () => <Text textAlign='center'>gur ternfl cvt</Text>,
  },
  {
    number: 7,
    pin: "1GX95",
    clue: () => (
      <Center>
        <Image src={originalOak} />
      </Center>
    ),
  },
  {
    number: 8,
    pin: "X7IR0",
    clue: () => (
      <Text textAlign='center'>7468696e67732e74656574682e6f696c73</Text>
    ),
  },
  {
    number: 9,
    pin: "9GDZL",
    clue: () => (
      <Center>
        <iframe
          title='Spotify'
          style={{ borderRadius: 12 }}
          src='https://open.spotify.com/embed/track/62yJjFtgkhUrXktIoSjgP2?utm_source=generator'
          width='100%'
          height='80'
          frameBorder='0'
          allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
        />
      </Center>
    ),
  },
  {
    number: 10,
    pin: "LWPFG",
    clue: () => (
      <Stack>
        <Text>Red, white and blue</Text>
        <Text>What does it mean to you?</Text>
        <Text>Surely you're proud, shout it aloud</Text>
        <Text>Britons, awake</Text>
      </Stack>
    ),
  },
  {
    number: 11,
    pin: "1PLGB",
    clue: () => (
      <Center>
        <Image src={failedMOT} />
      </Center>
    ),
  },
  {
    number: 12,
    pin: "6XVOG",
    clue: () => (
      <Center>
        <Image src={board} />
      </Center>
    ),
  },
];
function App() {
  const [value, setValue] = useState("");
  const clue = useMemo(() => {
    return clues.find(({ pin }) =>
      typeof pin === "function" ? pin(value) : pin === value
    );
  }, [value]);
  return (
    <Container>
      <Center flexDir='column' m={4}>
        <Heading mb={4} fontSize='2xl' textAlign='center'>
          Easter Egg Hunt
        </Heading>
        <HStack>
          <PinInput
            value={value}
            onChange={(value) => setValue(value.toUpperCase())}
            type='alphanumeric'
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <IconButton icon={<FaTimes />} onClick={() => setValue("")} />
        </HStack>
      </Center>
      {clue ? (
        <Box>
          <Heading mb={4} fontSize='lg'>
            Clue {clue.number}
          </Heading>
          {clue.clue()}
        </Box>
      ) : (
        <Heading textAlign='center' fontSize='xl'>
          Not a valid clue
        </Heading>
      )}
    </Container>
  );
}

export default App;
